/*
 VIA https://github.com/nahojd/vanilla-tooltip
 -- with modifications
*/

(function() {
    function Tip() {
        function isTip(element) {
            return element.classList.contains('tip');
        }

        function isInPopover(element) {
            if (!element || element.tagName.toLowerCase() === 'body')
                return false;

            if (element.classList.contains('popover-clone'))
                return true;

            return isInPopover(element.parentElement);
        }

        function hidePopovers() {
            const popovers = document.querySelectorAll('.popover-clone');
            for (let i = 0; i < popovers.length; i++) {
                document.body.removeChild(popovers[i]);
            }
            const links = document.querySelectorAll('.tip');
            for (let i = 0; i< links.length; i++) {
                links[i].dataOpenPopover = null;
            }
        }

        function toggleTooltip(target) {
            //Or create and show popover
            const originalPopover = target.nextElementSibling;
            if (!originalPopover)
                return false;

            const popover = originalPopover.cloneNode(true);
            popover.classList.add('popover-clone');
            document.body.appendChild(popover);
            setPosition(popover, target);
            target.dataOpenPopover = popover;

            return false;
        }

        function setPosition(popover, target) {

            const scrollY = window.pageYOffset || document.documentElement.scrollTop || 0;
            const scrollX = window.pageXOffset || document.documentElement.scrollLeft || 0;
            const targetRect = target.getBoundingClientRect();
            const targetWidth = targetRect.width || targetRect.left - targetRect.right;
            const targetHeight = targetRect.height || targetRect.top - targetRect.bottom;

            //Position the popup window
            popover.style.display = 'block';
            popover.style.position = 'absolute';
            let left = targetWidth / 2 + targetRect.left + scrollX - popover.clientWidth / 2;
            if (left < 10) { left = 10; }
            popover.style.left = left + 'px';
            popover.style.bottom = document.documentElement.clientHeight - targetRect.top - scrollY + targetHeight/2 + 'px';
            popover.style.top = 'inherit';
            popover.style.right = 'inherit';
            popover.style.zIndex = 10000;
        }

        function init() {
            document.body.addEventListener('click', function (e) {
                const target = e.target || e.srcElement;
                if (isTip(target) || isInPopover(target))
                    return;

                // hidePopovers();
            });

            // window.addEventListener('resize', hidePopovers);
        }


        return {
            hoverIn: toggleTooltip,
            hoverOut: hidePopovers,
            init: init
        };
    };

    window.vanillaTip = Tip();
})();

(function() {
    window.vanillaTip.init();

    let tooltips = document.getElementsByClassName('tip');

    if(tooltips){
        for(let i = 0, length1 = tooltips.length; i < length1; i++){
            // tooltips[i].addEventListener('click', function (event) {
            //      console.log(event, this, 'click');
            //      return vanillaTip.hoverIn(event);
            // });

            tooltips[i].onmousedown = function(){
                const popovers = document.querySelectorAll('.popover-clone');
                console.log('on mouse down');

                if(popovers.length == 0){
                    return vanillaTip.hoverIn(this);
                }
                else{
                    return vanillaTip.hoverOut(this);
                }
            };

            // tooltips[i].onmouseover = function(){
            //     console.log('on mouse over');
            //     // return vanillaTip.hoverIn(this);
            // };

            // tooltips[i].onmouseout = function(){
            //     console.log('on mouse out');
            //     // return vanillaTip.hoverOut(this);
            // };
        }
    }
})();
