const burger = document.getElementsByClassName('hamburger');

// console.log( burger, burger.length );

if(burger.length !== 0){
    burger[0].addEventListener("click", function() {

        const burgerBtn = this;
        const navMenu   = document.querySelector('.main-nav');
        const bodyTag   = document.getElementsByTagName("body");

        navMenu.classList.add('menu--animatable');
        burgerBtn.classList.toggle("is-active");

        bodyTag[0].classList.toggle("menu-is-active");


        navMenu.ontransitionend = () => {
            navMenu.classList.remove('menu--animatable');
        };

    }, false);

}

const expandableBtn = document.querySelector('.menu--expandable');

if(expandableBtn){
    expandableBtn.onclick = function(){
        expandableBtn.classList.toggle('expanded');
    };
}