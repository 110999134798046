const showLabelOnSelect = (input) => {
  const firstOpt = input.querySelector("option:first-child");

  if (firstOpt.hasAttribute("data-label")) {
    firstOpt.textContent = firstOpt.getAttribute("data-label");
    firstOpt.removeAttribute("data-label");
  }

  if (
    firstOpt.hasAttribute("data-label") ||
    firstOpt.hasAttribute("data-placeholder")
  ) {
    input.removeAttribute("style");
  }
};

document.querySelectorAll("select").forEach((select) => {
  select.addEventListener("blur", () => {
    showLabelOnSelect(select);
  });
});

setTimeout(() => {
  document.querySelectorAll("select").forEach((select) => {
    showLabelOnSelect(select);
  });
}, 100);
