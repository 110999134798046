if(window.location.host!=="rnrtires.local"){
    window.onload = function(){
        if(typeof podium_api_key !== 'undefined'){
            const widgetUrl = "https://connect.podium.com/widget.js";

            let tag = document.createElement('script');
                tag.setAttribute('src', widgetUrl+"#API_TOKEN="+podium_api_key);
                tag.setAttribute('defer','defer');
                tag.setAttribute('id', "podium-widget");
                tag.setAttribute('data-api-token', podium_api_key);

            document.body.appendChild( tag );
        }
    };
}