function anchorTo(){
    let internalAnchorLinks = document.querySelectorAll('[href*="#"]:not(.carousel-control-prev, .carousel-control-next)');

    for (var i = internalAnchorLinks.length - 1; i >= 0; i--) {
        let target = internalAnchorLinks[i].getAttribute('href');
        let dest = document.getElementById( target.replace("#",'') );

        if(dest !== null){
            internalAnchorLinks[i].onclick = function(event){
                event.preventDefault();
                document.body.parentNode.scrollTo({
                    top: dest.offsetTop - document.querySelector('.hdr_bar').offsetHeight,
                    left: 0,
                    behavior: 'smooth'
                });
            };
        }
    };
}

// First Anchor function on load.
anchorTo();

// Listen for cloned event to reregister new DOM elements
document.addEventListener('cloned', function(e) {
    anchorTo();
});